<template>
    <div class="page flex-col">
        <header>
            <img :src="backgroundImage" alt="">
            <div class="user">
                <div class="userPart">
                    <img @click="checkLogin"
                            class="image_6"
                            referrerpolicy="no-referrer"
                            src="./assets/img/53b0ccbf5db8cfc1a66e5783fe003148.png"
                    />
                    <div class="userInfo">
                        <span>{{userMember.name}}({{userMember.account}})</span>
                        <label class="userCard">
                            <span class="">{{userMember.typeName}}</span>
                        </label>
                    </div>

                </div>
                <div class="tuijianCode" @click="openInviteCode(userMember.inviteCode)">
                    <img src="./assets/img/qrCode.png" alt="">
                    <span class="codeText">我的推荐码</span>
                    <span>></span>
                </div>
            </div>
            <!-- 导航菜单 -->
           <!-- <div class="myOrderPart">
                <div class="myOrder">
                    <div class="orderTitle">
                        <h4>我的订单</h4>
                        <span @click="handleAllOrder">全部 <van-icon name="arrow" color="#999"/></span>
                    </div>
                    <nav class="orderNav">
                        <ul class="flex-row">
                            <li v-for="(item, index) in orderNavList" :key="index" @click="openOrder">
                                <img :src="item.iconUrl" alt="">
                                <span>{{ item.title }}</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>-->
        </header>
        <section>
            <div class="ads flex-row">
                <div class="ad ad-left" @click="openZhuce">
                    <img src="./assets/img/ad_left.png" alt="">
                </div>
                <div class="ad ad-right" @click="openYouhuiquan">
                    <img src="./assets/img/ad_right.png" alt="">
                </div>
            </div>
            <!-- main -->
            <div class="main">
                <div class="item" v-for="(item, index) in loopData" :key="index">
                    <div class="flex-row func" @click="openPage(item.title1)">
                        <div class="iconName">
                            <img :src="item.iconUrl1"/>
                            <span v-html="item.title1"></span>
                        </div>
                        <img class="goto" :src="item.arrow"/>
                    </div>
                    <van-divider/>
                    <div class="flex-row func" @click="openPage(item.title2)">
                        <div class="iconName">
                            <img :src="item.iconUrl2"/>
                            <span v-html="item.title2"></span>
                        </div>
                        <img class="goto" :src="item.arrow"/>
                    </div>
                </div>

            </div>
            <div class="ads1 flex-row">
            </div>
        </section>
        <footer>
            <ul class="navFooter">
                <li v-for="(item, index) in navFooterList" :key="item.id" :class="activeFooter == index?'active':''"
                    @click="handleNavFooter(index, item)">
                    <img :src="activeFooter == index?item.iconActiveUrl:item.iconUrl" alt="">
                    <span>{{ item.title }}</span>
                </li>
            </ul>
            <br>
        </footer>
    </div>
</template>
<script>
    import {queryUserByMemberId} from "@/api/user";

    export default {
        data() {
            return {
                isWeChatOpened: false,
                memberId: '',
                memberAccount:'',
                memberLevel:'',
                userMember: {},
                openIdParams: {},
                activeFooter: 1,
                backgroundImage: require('./assets/img/header_logo.png'),
                adLeftUrl: require('./assets/img/ad_left.png'),
                adRightUrl: require('./assets/img/ad_right.png'),
                orderNavList: [
                    {
                        id: 1,
                        title: '待发货',
                        iconUrl: require('./assets/img/105c1540cbb93b640c87310f6565125b.png'),
                        path: '/home'
                    },
                    {
                        id: 2,
                        title: '待收货',
                        iconUrl: require('./assets/img/15aa10867089260624d049a0198ad19f.png'),
                        path: '/home'
                    },
                    {
                        id: 3,
                        title: '已发货',
                        iconUrl: require('./assets/img/bb73fb3c664fbb3f42bd958963239938.png'),
                        path: '/home'
                    },
                ],
                navFooterList: [
                    {
                        id: 1,
                        title: '首页',
                        iconUrl: require('./assets/img/home.png'),
                        iconActiveUrl: require('./assets/img/home-active.png'),
                        path: '/home'
                    },
                    {
                        id: 2,
                        title: '商城',
                        iconUrl: require('./assets/img/stores.png'),
                        iconActiveUrl: require('./assets/img/stores-active.png'),
                        path: '/stores'
                    },
                    {
                        id: 3,
                        title: '我的',
                        iconUrl: require('./assets/img/wode.png'),
                        iconActiveUrl: require('./assets/img/wode-active.png'),
                        path: '/wode'
                    },
                ],
                loopData: [
                    {
                        /*iconUrl1: require('./assets/img/yaoqingma.png'),
                        title1: '邀请码',
                        arrow: require('./assets/img/right_arrow.png'),
                        iconUrl2: require('./assets/img/yaoqingyouli.png'),
                        title2: '邀请有礼',*/
                        iconUrl1: require('./assets/img/dingdan.png'),
                        title1: '我的订单',
                        arrow: require('./assets/img/right_arrow.png'),
                        iconUrl2: require('./assets/img/yaoqingma.png'),
                        title2: '邀请码',
                    },
                    {
                        iconUrl1: require('./assets/img/dingdan.png'),
                        title1: '我的推广',
                        arrow: require('./assets/img/right_arrow.png'),
                        iconUrl2: require('./assets/img/yaoqingma.png'),
                        title2: '推广收益',
                    },
                    {
                        iconUrl1: require('./assets/img/tixian.png'),
                        title1: '提现记录',
                        arrow: require('./assets/img/right_arrow.png'),
                        iconUrl2: require('./assets/img/qianbao.png'),
                        title2: '我的钱包',
                    },
                    {
                        iconUrl1: require('./assets/img/dizhi.png'),
                        title1: '我的地址',
                        arrow: require('./assets/img/right_arrow.png'),
                        iconUrl2: require('./assets/img/kefu.png'),
                        title2: '联系客服'
                    },
                    {
                        iconUrl1: require('./assets/img/gerenxinxi.png'),
                        title1: '个人信息',
                        arrow: require('./assets/img/right_arrow.png'),
                        iconUrl2: require('./assets/img/tuichu.png'),
                        title2: '退出登录'
                    }
                ]
            };
        },
        created(){
            const user = localStorage.getItem('userInfoDs')
            if(!user){
                this.$router.push('/login')
                return
            }
            this.memberId = JSON.parse(user).memberId
            this.memberAccount = JSON.parse(user).account
            this.memberLevel = JSON.parse(user).level
        },
        mounted() {
            queryUserByMemberId(this.memberId).then(response => {
                if (response.code == 200) {
                    this.userMember = response.data
                }
            });

            // 判断当前路径
            let path = this.$route.path
            if (path == '/home') {
                this.activeFooter = 0
            } else if (path == '/stores') {
                this.activeFooter = 1
            } else {
                this.activeFooter = 2
            }

        },
        methods: {
            openOrder(){
                this.$model({
                    show: true,
                    title: "提示",
                    content: "敬请期待",
                    confirmButton: false,
                    cancelButton: true
                });
                return false
            },
            openZhuce(){
                this.$router.push('/register')
            },
            openYouhuiquan(){
                if(this.memberAccount && this.memberLevel>0){
                    this.$router.push('/userCoupon')
                }else{
                    this.$router.push('/login')
                }
            },
            openInviteCode(inviteCode){
                if(this.memberAccount && this.memberLevel>0){
                    this.$router.push({ name: 'invitationcode', params: { code: inviteCode }});
                }else{
                    this.$router.push('/login')
                }
            },
            checkLogin(){
                if(this.memberAccount && this.memberLevel>0){
                    this.$router.push('/userinfo')
                }else{
                    this.$router.push('/login')
                }
            },
            openPage(title){
                if(this.memberAccount ){
                    if(title =='邀请码' ){
                        if(this.memberLevel>0){
                            this.$router.push({ name: 'inviteCode', query: { fatherCode: this.userMember.fatherCode }});
                        }else{
                            this.$router.push('/login')
                        }
                    }
                    if(title =='我的订单'){
                        this.$router.push('/userOrder')
                    }
                    if(title =='邀请有礼' && this.memberLevel>0){
                        if(this.memberLevel>0){
                            this.$router.push({ name: 'invitationcode', params: { code: this.userMember.inviteCode }});
                        }else{
                            this.$router.push('/login')
                        }

                    }
                    if(title =='提现记录' ){
                        this.$router.push('/withdrawal')
                    }
                    if(title =='我的推广' ){
                        if(this.memberLevel>0){
                            this.$router.push('/withdrawaltg')
                        }else {
                            this.$router.push('/login')
                        }

                    }
                    if(title =='我的钱包' || title =='推广收益'){
                        if(this.memberLevel>0){
                            this.$router.push('/withdrawal')
                        }else{
                            this.$router.push('/login')
                        }

                    }
                    if(title =='我的地址'){
                        if(this.memberLevel>0){
                            this.$router.push('/userAddress')
                        }else{
                            this.$router.push('/login')
                        }

                    }
                    if(title =='联系客服'){
                        const phoneNumber = '10086'; // 电话号码
                        const link = `tel:${phoneNumber}`;
                        if ('clipboard' in navigator) {
                            navigator.clipboard.writeText(phoneNumber);
                        }
                        window.location.href = link;
                    }
                    if(title =='个人信息'){
                        if(this.memberLevel>0){
                            this.$router.push('/userinfo')
                        }else{
                            this.$router.push('/login')
                        }

                    }
                    if(title =='退出登录'){
                        localStorage.removeItem("userInfoDs");
                        this.$router.push('/home')
                    }
                }else{
                    this.$router.push('/login')
                }
            },
            // 引入header背景图
            backgroundStyle() {
                return {
                    background: `url(${this.backgroundImage})`,
                    backgroundSize: 'cover',
                    height: '500px' // 根据需要设置高度
                };
            },
            // 尾部导航
            handleNavFooter(index, item) {
                this.activeFooter = index
                console.log('item', item);
                if(item.id<3){
                    this.$router.push(item.path)
                }
            },
            handleAllOrder() {
                this.$router.push('/userOrder')
            }
        }
    };
</script>
<style scoped>

    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        font-size: 38px;
        height: 100%;
    }

    .page {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        display: flex;
    }

    header {
       /* height: 17.8rem;*/
        position: relative;

    }

    footer {
        height: 3.95rem;
    }

    section {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
       /* padding: 0 0 1rem 1.33rem;*/
        overflow: auto;
    }

    .label_1 {
        margin: 0.187rem 0 0 19.876rem;
    }

    header img {
        width: 100%;
        height: 9.3rem;
    }

    .user {
        width: 100%;
        height: 3.68rem;
        position: absolute;
        top: 2rem;
        left: 0;
        padding-left: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .userPart {
        display: flex;
    }

    .userPart img {
        width: 3.68rem;
        height: 3.68rem;
    }

    .userInfo {
        display: flex;
        flex-direction: column;
        margin: 0.427rem 0 0 0.694rem;
    }

    .userInfo > span {
        width: 6.454rem;
        height: 1.067rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.12rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.12rem;
    }

    .userInfo .userCard {
        display: inline-block;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 4px;
        height: 1.5rem;
        padding: 5px 0.53rem;
        margin-top: 0.8rem;
        font-size: 0.64rem;
    }

    .userCard span:nth-child(1) {
        color: rgba(255, 136, 60, 1);
        font-size: 0.66rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 0.66rem;
    }

    .userCard span:nth-child(2) {
        color: rgba(255, 136, 60, 1);
        font-size: 0.66rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 0.66rem;
    }

    .tuijianCode {
        width: 8.8rem;
        height: 2.13rem;
        background-color: #bf5d22;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .tuijianCode img {
        width: 0.96rem;
        height: 0.96rem;
    }

    .tuijianCode span.codeText {
        margin: 0 0.32rem;
    }

    .myOrderPart {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0 1.33rem;
    }

    .myOrder {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        height: 9.334rem;
        padding: 1.46rem;
    }

    .orderTitle {
        display: flex;
        justify-content: space-between;
    }

    .orderTitle span {
        color: #999;
    }

    .orderNav, ul {
        width: 100%;
    }

    .orderNav {
        padding-top: 1.2rem;
    }

    .orderNav li {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .orderNav li img {
        width: 1.227rem;
        height: 1.654rem;
    }

    .orderNav li span {
        margin-top: 0.66rem;
        font-size: 0.64rem;
        color: #999;
    }

    .ads {
        justify-content: space-between;
        margin: 0.85rem 0 0;
        height: 5rem;
        width: 100%;
    }

    .ads1 {
        justify-content: space-between;
        margin: 0.85rem 0 0;
        height: 0rem;
        width: 100%;
    }

    .ad {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        border-radius: 20px;
        width: 16.5rem;
    }

    .ad img {
        width: 100%;
        height: 100%;
    }

    .item {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        border-radius: 20px;
        padding: 1.6rem;
        margin-top: 0.85rem;
    }

    .func {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        color: #333;
    }

    .func img {
        width: 1.334rem;
        height: 1.334rem;
        margin-right: 0.64rem;
    }

    .func img.goto {
        width: 0.56rem;
        height: 0.987rem;
    }

    .van-divider {
        margin: 1.6rem 0;
    }

    /* 底部导航 */
    .navFooter {
        display: flex;
        height: 100%;
    }

    .navFooter li {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .navFooter li.active span {
        color: #fb6d36;
    }

    .navFooter li img {
        width: 1.86rem;
        height: 1.86rem;
    }

    .navFooter li span {
        display: inline-block;
        padding: 0.26rem 0 0 0;
        line-height: 1.28rem;
        font-size: 0.75rem;
        color: #666;
    }
</style>