import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
//axios.defaults.baseURL = 'http://localhost:82/dev-api';
axios.defaults.baseURL = 'http://124.221.229.122:82/prod-api';

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: axios.defaults.baseURL,
    timeout: 10000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    //config.headers['token'] = 'token';
    return config
}, error => {
    console.log(error)
    return error;
})

// 响应拦截器
service.interceptors.response.use(response => {
        return response.data;
    },
    error => {
        console.log('err' + error)
        return error.response.data;
    }
)

export default service
