<template>
    <div class="page flex-col">
        <div class="box_1 flex-col">
            <div class="image-wrapper_1 flex-col">
                <img style="z-index: 1"
                     class="image_1"
                     referrerpolicy="no-referrer"
                     src="./assets/img/6df108df911193a6b2d90de5e8b7d3b9.png"
                />
            </div>
            <div class="box_4 flex-col" style="text-align: justify;">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item label="手机号" prop="account" class="text-wrapper_1 flex-row">
                        <el-input v-model="form.account" class="pass_input" placeholder="请输入手机号"/>
                    </el-form-item>
                    <br>
                    <el-form-item label="密 码" prop="pwd" class="text-wrapper_3 flex-row">
                        <el-input v-model="form.pwd" type="password" class="pass_input" placeholder="请输入密码"/>
                    </el-form-item>
                </el-form>
                <div class="text-wrapper_5 flex-col" @click="submitForm()">
                    <button class="text_11">登录</button>
                </div>
                <div style="margin: -2.24rem 2.24rem 0rem 1.44rem;">
                    <router-link to="/home">
                        <div style="float: left;font-size: 1.386rem;">回到首页</div>
                    </router-link>
                    <router-link to="/register">
                        <div style="float: right;font-size: 1.386rem;">没有账号？注册</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {login} from "@/api/user";

    export default {
        data() {
            return {
                form: {
                    account: null,
                    pwd: null
                },
                openIdParams: {},
                code: '',
                rules: {
                    account: [
                        {required: true, message: "手机号不能为空", trigger: "blur"}
                    ],
                    pwd: [
                        {required: true, message: "密码不能为空", trigger: "blur"}
                    ]
                }
            };
        },
        created() {
            const account = this.$route.params.account;
            if(account){
                this.form.account = account
            }
        },
        methods: {
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        login(this.form).then(response => {
                            if (response.code == 200) {
                                localStorage.setItem('userInfoDs', JSON.stringify(response.data))
                                this.$router.push('/home')
                            }else{
                                this.$model({
                                    show: true,
                                    title: "提示",
                                    content: response.msg,
                                    confirmButton: false,
                                    cancelButton: true
                                });
                                return false
                            }
                        });
                    }
                });
            }
        }
    };
</script>

<style scoped lang="css" src="./assets/index.rem.css"/>

<style scoped lang="css">
    .flex-row {
    }

    ::v-deep .el-form-item__label:before {
        content: '';
    }

    ::v-deep .el-form-item__label {
        line-height: 4.2rem;
        margin: 0 0 0 1.387rem;
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
    }

    .pass_input {
    }

    ::v-deep .el-input__inner {
        height: 4rem;
        border: none;
        background-color: rgba(245, 245, 245, 1);
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
    }

    .father {
        position: relative;
    }

    .success-box {
        width: 130px;
        height: 130px;
        border-radius: 8px;
        background: rgba(64, 64, 64, 0.88);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 21px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -65px;
        margin-top: -65px;
        color: #FFF;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

</style>
