import 'vant/lib/index.css';
import Vue from 'vue'
import VueModel from './views/model/index.js';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'

import Vant from 'vant'; // 导入 Vant 组件库
import 'vant/lib/index.css'; // 导入样式文件
import { Lazyload } from 'vant';

Vue.use(Lazyload);
// 全局注册 Vant 组件库
Vue.use(Vant);
// import {Button,DropdownMenu,DropdownItem,Cell,Switch,Field} from 'vant'
// import { RadioGroup, Radio } from 'vant';
// import { CheckboxGroup, Checkbox } from 'vant';
// import { Tab, Tabs } from 'vant';
// import { Tabbar, TabbarItem } from 'vant';
// import { NavBar, Icon, Tag } from 'vant';

// 引入其他图标
import "./assets/font/iconfont.css"

Vue.use(VueModel);
Vue.use(ElementUI);

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
