<template>
    <div class="page flex-col">
        <div class="box_1 flex-col">
            <div class="box_2 flex-col">
                <div class="box_3 flex-col">
                    <div class="image-wrapper_1 flex-col">
                        <img style="z-index: 1"
                                class="image_1"
                                referrerpolicy="no-referrer"
                                src="./assets/img/6df108df911193a6b2d90de5e8b7d3b9.png"
                        />
                    </div>
                </div>
                <div class="box_4 flex-col">
                    <el-form ref="form" :model="form" :rules="rules">
                        <!--<div class="text-wrapper_1 flex-row">
                            <span class="text_1">姓名</span>
                            <span contenteditable id="name" class="text_2"></span>
                            &lt;!&ndash;<input type="text" class="text_2" v-model="form.name"
                                   style="border: none; width: 21.654rem;" placeholder="请输入姓名"/>&ndash;&gt;
                        </div>-->
                       <!-- <div class="text-wrapper_1 flex-row">
                            <span class="text_1">姓名</span>
                            <el-input v-model="form.name" class="pass_input" placeholder="请输入姓名"  />
                        </div>-->
                        <el-form-item label="姓 名" prop="name" class="text-wrapper_1 flex-row">
                            <el-input v-model="form.name" class="pass_input" placeholder="请输入姓名"  />
                        </el-form-item>
                        <el-form-item label="手机号" prop="account" class="text-wrapper_2 flex-row">
                            <el-input v-model="form.account" class="pass_input" placeholder="请输入手机号"/>
                        </el-form-item>
                        <el-form-item label="密 码" prop="pwd" class="text-wrapper_3 flex-row">
                            <el-input v-model="form.pwd" type="password" class="pass_input" placeholder="请输入密码"  />
                        </el-form-item>
                        <el-form-item label="推荐人" prop="fatherCode" class="text-wrapper_4 flex-row">
                            <el-input v-model="form.fatherCode" :disabled="isDisabled" class="pass_input" placeholder="请输入邀请码或手机号"  />
                        </el-form-item>
                        <el-select class="text-wrapper_4 flex-row"
                                v-model="form.shopArea"
                                placeholder="请选择地域"
                                @change="tagChange()"
                                >
                            <el-option v-for="item in dataList"
                                :key="item"
                                :value="item"
                                :label="item"
                            />
                        </el-select>
                        <el-select class="text-wrapper_4 flex-row"
                                   v-model="form.shopId"
                                   placeholder="请选择店铺"
                        >
                            <el-option v-for="item in dataShopList"
                                       :key="item.shopId"
                                       :value="item.shopId"
                                       :label="item.shopName"/>
                        </el-select>

                       <!-- <div class="section_1 flex-row">
                          <span class="text_9">会员类型</span>
                          <select class="text_10" v-model="type" style="border: none;width: 12.654rem;">
                            <option>金卡</option>
                            <option>银卡</option>
                          </select>
                        </div>-->

                    </el-form>
                    <div class="text-wrapper_5 flex-col" @click="submitForm()">
                        <button class="text_11">立即注册</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {register,queryShopAreaList} from "@/api/user";

    export function getQueryObject(url) {
        url = url == null ? window.location.href : url
        const search = url.substring(url.lastIndexOf('?') + 1)
        const obj = {}
        const reg = /([^?&=]+)=([^?&=]*)/g
        search.replace(reg, (rs, $1, $2) => {
            const name = decodeURIComponent($1)
            let val = decodeURIComponent($2)
            val = String(val)
            obj[name] = val
            return rs
        })
        return obj
    }

    export default {
        data() {
            return {
                form: {
                    name: null,
                    account: null,
                    pwd: null,
                    fatherCode: null,
                    shopArea: null,
                    shopId: null,
                },
                isDisabled:false,
                rules: {
                    name: [
                        {required: true, message: "姓名不能为空", trigger: "blur"}
                    ],
                    account: [
                        {required: true, message: "手机号不能为空", trigger: "blur"}
                    ],
                    pwd: [
                        {required: true, message: "密码不能为空", trigger: "blur"}
                    ],
                    fatherCode: [
                        {required: false, message: "推荐人不能为空", trigger: "blur"}
                    ],
                },
                shopArea: '',
                dataList: [],
                dataShopList: []
            };
        },
        created(){
           /* this.$router.push({ name: 'invitationcode', params: { code: '9h84y8ob' }});*/
            let verifyCode = getQueryObject(window.location.href).verifyCode;
            if(verifyCode){
                this.form.fatherCode = verifyCode;
                this.isDisabled = true
            }

            queryShopAreaList(this.shopArea).then(res => {
                this.dataList = res.data
            });
        },
        methods: {
            tagChange(){
                let target = this.form.shopArea;
                queryShopAreaList(target).then(res => {
                    this.dataShopList = res.data
                });
            },
            submitForm() {
                //var spanValue = document.getElementById("name").innerHTML;
                const mobileReg = '^1\\d{10}$'
                if (!new RegExp(mobileReg).test(this.form.account)) {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "手机号码格式不正确",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        register(this.form).then(response => {
                            this.$model({
                                show: true,
                                title: "提示",
                                content: response.msg,
                                confirmButton: false,
                                cancelButton: true
                            });
                            if(response.code==200){
                                let account = this.form.account
                                //this.$router.push('/home')
                                //this.$router.push({ name: 'invitationcode', params: { code: response.data.inviteCode }});
                                this.$router.push({ name: 'login', params: { account: account }});
                            }
                        });
                    }
                });
            }

        }
    };
</script>

<style scoped lang="css" src="./assets/index.rem.css" />

<style scoped lang="css">
    .flex-row{
    }
    ::v-deep .el-form-item__label:before {
          content: '';
      }
    ::v-deep .el-form-item__label {
          line-height: 4.2rem;
          margin: 0 0 0 1.387rem;
          font-size: 1.28rem;
          font-family: PingFang-SC-Medium;
      }
    .pass_input {
    }
    ::v-deep .el-input__inner {
          height: 4rem;
          border: none;
          background-color: rgba(245, 245, 245, 1);
          font-size: 1.28rem;
          font-family: PingFang-SC-Medium;
      }

    .father{
        position: relative;
    }
    .success-box {
        width: 130px;
        height: 130px;
        border-radius: 8px;
        background: rgba(64, 64, 64, 0.88);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 21px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -65px;
        margin-top: -65px;
        color: #FFF;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

</style>
