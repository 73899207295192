<template>
    <div class="page flex-col">
        <div class="box_1 flex-col">
            <img referrerpolicy="no-referrer" src="./assets/img/code.png"/>
            <div class="icode1">
                <span class="text1">{{invitatioCode}}</span><br>
            </div>
            <div class="icode2">
                <span class="text2">我的推荐码</span>
            </div>
            <div class="icode3">
                <img :src="'data:image/*;base64,' + invitatioCodeUrl" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import {queryCode} from "@/api/user";

    export default {
        data() {
            return {
                invitatioCode: '',
                invitatioCodeUrl: ''
            };
        },
        created() {
            const code = this.$route.params.code;
            if(code){
                this.invitatioCode = code;
                this.queryCode(code);
            }else{
                this.$router.push('/wode')
            }
        },
        methods: {
            async queryCode(code) {
                queryCode(code).then(response => {
                    if(response.code==200){
                        this.invitatioCodeUrl = response.data;
                    }
                });
            }

        }
    };
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>

<style scoped lang="css">
    .icode1 {
        position:absolute;
        left: 10.5rem;
        top: 9.5rem;
        height: 103px;
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
        color: #B42424;
        line-height: 125px;
    }
    .icode2 {
        position:absolute;
        left: 14rem;
        top: 13.5rem;
        height: 103px;
        font-size: 1.28rem;
        font-family: PingFang SC;
        color: #CE3444;
        line-height: 125px;
    }
    .icode3 {
        position:absolute;
        left: 4.3rem;
        top: 25rem;
        height: 103px;
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
        color: #B42424;
        line-height: 125px;
    }
    .text1 {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 3rem;
        color: #B42424;
        line-height: 132px;
    }
    .text2 {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 1.28rem;
        color: #CE3444;
        line-height: 52px;
    }
</style>