<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
    <div class="layout-footer" style="display:none;position: absolute;bottom:0;left:0;background-color: pink;" v-if="$route.meta.footBarShow">
      <!-- <TabBar :data="tabbars" @change="handleChange" /> -->
      <p>这里是最后</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    provide () {
      return {
        reload: this.reload
      }
    },
    data () {
      return {
        isShow: true
      }
    },
    methods: {
      reload () {
        this.isShow = false
        this.$nextTick(function () {
          this.isShow = true
        })
      }
    }
  }
</script>
<style lang="css" src="./assets/common.css">
</style>
