import model from '../model/index.vue';

export default {
    install(Vue) {

        const defaults = {
            show: false,
            mask: true,
            title: '提示',
            content: '这是正文',
            confirmButton: true,
            cancelButton: true,
            confirmText: '确认',
            cancelText: '关闭',
            cancelCallBack: () => {},
            confirmCallBack: () => {}
        };

        const modelVueConstructor = Vue.extend(model);

        Vue.prototype.$model = (options = {}) => {
            if (Vue.prototype.$isServer) return;
            options = Object.assign({}, defaults, options);
            let parent = document.body ;
            let instance = new modelVueConstructor({
                el: document.createElement('div'),
                data: options
            });
            parent.appendChild(instance.$el);

            return instance;
        };

    },
};