<!-- 附近门店列表 -->
<template>
    <div class="page">
        <header>
            <van-nav-bar
                    title="精选门店"
                    left-text=""
                    left-arrow
                    @click-left="onClickLeft"
            />
        </header>
        <section>
            <div class="box store" v-for="(item, index) in shopList" :key="index"
                 @click="handleClickStoreInfo(item)">
                <!-- <div class="nearbyStoreInfo"> -->
                <div class="storeJieshao">
                    <img :src="item.imagePathUrl" alt="">
                    <div class="detail">
                        <h3>
                            {{ item.shopName }}
                        </h3>
                        <!--<div class="rate">
                            <star :score="item.rate"></star>
                        </div>-->
                        <div class="businessHours">
                            <span>营业</span>
                            <span>{{ item.shopHours }}</span>
                        </div>
                    </div>
                </div>
                <div class="storeAddress">
                    地址：{{ item.shopAddress }}
                </div>
                <!-- </div> -->
            </div>
        </section>
    </div>
</template>

<script>
    import {queryShopList} from "@/api/home";
    import axios from 'axios'
    //import Star from '@/components/star.vue'
    export default {
        name: '',
        /*  components: {
            Star,
          },*/
        data() {
            return {
                baseURL: axios.defaults.baseURL,
                shopList: [],
                shopParam: {
                    type: 2,
                    status: 'Y',
                    dr: 1
                },
            };
        },
        mounted() {
            queryShopList(this.shopParam).then(response => {
                if(response.code==200){
                    response.data.forEach(item =>{
                        if(item.imagePath){
                            item.imagePathUrl = this.baseURL+item.imagePath
                        }else{
                            item.imagePathUrl = require('../home/assets/img/storesPic.png')
                        }
                        this.shopList.push(item)
                    })

                }
            });
        },
        methods: {
            // 返回
            onClickLeft() {
                this.$router.push('/home')
            },
        }
    };

</script>
<style lang="css" scoped>
    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        font-size: 38px;
        height: 100%;
    }

    .page {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    header {
        height: 4rem;
        background-color: pink;
    }

    section {
        flex: 1;
        background-color: #f3f3f3;
        padding: 1.6rem 1.28rem;
        overflow: auto;
    }

    .box {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        padding: 1.46rem;
        background-color: #fff;
        margin-bottom: 1.06rem;
    }

    ::v-deep .van-nav-bar .van-icon {
        color: #666;
    }

    /* copy store */
    .storeJieshao {
        display: flex;
    }

    .storeJieshao > img {
        width: 6.93rem;
        height: 4.8rem;
        margin-right: 0.8rem;
    }

    .detail {
        display: flex;
        flex-direction: column;
    }

    .detail h3 {
        height: 1.38rem;
        line-height: 1.38rem;
        font-size: 1.06rem;
        color: #333;
    }

    .detail .rate img {
        width: 1.12rem;
        height: 1.12rem;
    }

    .businessHours {
        margin-top: 0.3rem;
    }

    .businessHours span:nth-child(1) {
        display: inline-block;
        padding: 0.13rem 0.32rem;
        font-size: 0.69rem;
        color: #fff;
        background-color: #fc7a3d;
        border-radius: 3px;
    }

    .businessHours span:nth-child(2) {
        font-size: 0.69rem;
        color: #999;
        margin-left: 0.53rem;
    }

    .storeAddress {
        margin-top: 0.8rem;
        font-size: 0.8rem;
        color: #999;;
    }
</style>
