import request from '@/api/request'

// 广告位轮播图查询
export function queryBannerList(data) {
  return request({
    url: '/external/interface/queryBannerList',
    method: 'get',
    params: data
  })
}

// 门店列表查询
export function queryShopList(data) {
  return request({
    url: '/external/interface/queryShopList',
    method: 'get',
    params: data
  })
}


