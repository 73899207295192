<template>
    <div class="page">

        <header :style="{ backgroundImage: 'url(' + require('@/assets/img/header_logo.png') + ')', height: '15.2rem', }">
            <div class="box_6 flex-row">

                <!-- <img class="label_7" referrerpolicy="no-referrer" src="./assets/img/e2db04c44c67cbb3024ddbfacf55d401.png" />
                 <span class="text_9">当前位置展示</span>
                 <img class="image_9" referrerpolicy="no-referrer" src="./assets/img/9eba6ed5e3a44bee5a6a77f307923c3c.png" />
               -->
            </div>

            <div class="swiperPart">
                <van-swipe :autoplay="2000">
                    <van-swipe-item v-for="(image, index) in bannerImages" :key="index">
                        <img v-lazy="image"/>
                        <!--<img :src="item.imagePathUrl" alt="">-->
                    </van-swipe-item>
                </van-swipe>
            </div>

        </header>
        <nav class="homeNav">
            <div class="navbox">
                <div class="navItem" v-for="(item, index) in navList" :key="index" @click="handleClick(item)">
                    <img :src="item.imgUrl" :alt="item.title">
                    <span>{{ item.title }}</span>
                </div>
            </div>

        </nav>
        <section>
            <div class="flex-row storesHeader">
                <div class="storesTitle">精选门店</div>
                <span class="text_2" @click="moreStores">更多<van-icon name="arrow" color="#999"/></span>
            </div>
            <div class="storeList">
                <div class="box store" v-for="(item, index) in shopList" :key="index"
                     @click="handleClickStoreInfo(item.shopId)">
                    <!-- <div class="nearbyStoreInfo"> -->
                    <div class="storeJieshao">
                        <img :src="item.imagePathUrl" alt="">
                        <div class="detail">
                            <h3>
                                {{ item.shopName }}
                            </h3>
                           <!-- <div class="rate">
                                 <star :score="item.rate"></star>
                            </div>-->
                            <div class="storeAddress">
                                {{ item.shopHours }}
                            </div>
                            <div class="storeAddress">
                                {{ item.shopAddress }}
                            </div>
                        </div>
                    </div>

                    <!-- </div> -->
                </div>
            </div>
            <div class="storeList">
                <div class="box1 store">
                    <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023024655号-3</a>
                </div>
            </div>
        </section>
        <footer>
            <!-- 底部导航 -->
            <ul class="navFooter">
                <li v-for="(item, index) in navFooterList" :key="item.id" :class="activeFooter == index ? 'active' : ''"
                    @click="handleNavFooter(index, item)">
                    <img :src="activeFooter == index ? item.iconActiveUrl : item.iconUrl" alt="">
                    <span>{{ item.title }}</span>
                </li>
            </ul>
        </footer>

    </div>
</template>
<script>
    import {queryBannerList, queryShopList} from "@/api/home";
    import axios from 'axios'

    export default {
        data() {
            return {
                baseURL: axios.defaults.baseURL,
                bannerParam: {
                    status: 'Y',
                    dr: 1
                },
                shopParam: {
                    type: 2,
                    status: 'Y',
                    dr: 1
                },
                activeFooter: 0,
                bannerImages: [],
                navList: [
                    {
                        imgUrl: require('./assets/img/5ee9b3e91d40e6e7c6c316e78e106d82.png'),
                        title: '优选商城',
                        path: 'stores'
                    },
                    {
                        imgUrl: require('./assets/img/d2a8149d5a2377f500eac7f5d6609eb6.png'),
                        title: '会员介绍',
                        path: 'membersIntroduction'
                    },
                    {
                        imgUrl: require('./assets/img/789460667237e4064cb6104b0a15fbab.png'),
                        title: '最新活动',
                        path: 'newParty'
                    },
                    {
                        imgUrl: require('./assets/img/71f9f209d0efe072ba32d7f3e251c1ec.png'),
                        title: '战略合作',
                        path: 'cooperate'
                    },
                ],
                bannerList: [
                    {
                        imagePathUrl: ''
                    }
                ],
                shopList: [],
                navFooterList: [
                    {
                        id: 1,
                        title: '首页',
                        iconUrl: require('@/assets/img/home.png'),
                        iconActiveUrl: require('@/assets/img/home-active.png'),
                        path: '/home'
                    },
                    {
                        id: 2,
                        title: '商城',
                        iconUrl: require('@/assets/img/stores.png'),
                        iconActiveUrl: require('@/assets/img/stores-active.png'),
                        path: '/stores'
                    },
                    {
                        id: 3,
                        title: '我的',
                        iconUrl: require('@/assets/img/wode.png'),
                        iconActiveUrl: require('@/assets/img/wode-active.png'),
                        path: '/wode'
                    },
                ],
                constants: {}
            };
        },

        mounted() {

            // 判断当前路径
            let path = this.$route.path
            if (path == '/home') {
                this.activeFooter = 0
            } else if (path == '/stores') {
                this.activeFooter = 1
            } else {
                this.activeFooter = 2
            }
            this.queryBannerList();
            this.queryShopList();
        },
        methods: {

            async queryBannerList() {
                queryBannerList(this.bannerParam).then(response => {
                    if(response.code==200){
                        response.data.forEach(item =>{
                            if(item.bannerImage){
                                item.imagePathUrl = this.baseURL+item.bannerImage
                            }else{
                                item.imagePathUrl = require('./assets/img/banner.png')
                            }
                            this.bannerImages.push(item.imagePathUrl)
                        })
                    }
                });
            },
            async queryShopList() {
                queryShopList(this.shopParam).then(response => {
                    if(response.code==200){
                        response.data.forEach(item =>{
                            if(item.imagePath){
                                item.imagePathUrl = this.baseURL+item.imagePath
                            }else{
                                item.imagePathUrl = require('./assets/img/storesPic.png')
                            }
                            this.shopList.push(item)
                        })

                    }
                });
            },
            // nav 点击事件
            handleClick(item) {
                console.log(item.path);
                switch (item.path) {
                    case 'stores':
                        this.$router.push('/stores')
                        break;
                    case 'membersIntroduction':
                        this.$router.push('/membersIntroduction')
                        break;
                    case 'newParty':
                        this.$router.push('/activity')
                        break;
                    case 'cooperate':
                        this.$router.push('/stores')
                        break;
                }

            },
            moreStores() {
                this.$router.push('/nearbyStoresList')
            },
            // 尾部导航
            handleNavFooter(index, item) {
                this.activeFooter = index
                console.log('item', item);
                if(item.id>1){
                    this.$router.push(item.path)
                }
            },
            // 进入商家
            handleClickStoreInfo(shopId) {
                this.$router.push({ name: 'enterMall', query: { shopId: shopId }});
            }
        }
    };
</script>
<!-- <style scoped lang="css" src="./assets/index.rem.css" /> -->
<style scoped>
    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        font-size: 37.5px;
        height: 100%;
    }

    .page {
        width: 100%;
        height: 100vh;
        background-color: #f3f3f3;
        display: flex;
        flex-direction: column;
    }

    header {
        height: 17.5rem;
        /* 610px*/
        background-position-x: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #f3f3f3;
        position: relative;
    }

    footer {
        height: 3.92rem;
        background-color: #fff;
    }

    section {
        flex: 1;
        padding: 0.42rem 1.33rem 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .homeNav {
        padding: 0 1.33rem;
    }

    .box {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        padding: 1.12rem;
        background-color: #fff;
        margin-bottom: 1.06rem;
    }

    .box1 {
        padding: 0.2rem 10rem;
     /*   background-color: #fff;*/
        position: absolute;
        bottom: 4rem;
    }

    .navbox {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        padding: 1.46rem;
        background-color: #fff;
        display: flex;
        flex-direction: row;
    }

    .swiperPart {
        height: 11.35rem;
        padding: 0 1.33rem;
        overflow: hidden;
        border-radius: 25px;
    }

    .van-swipe, .van-swipe-item, .van-swipe-item img {
        width: 100%;
        height: 100%;
    }

    .swiperPart img {
        width: 100%;
        height: 11.35rem;
    }

    .storesHeader {
        height: 3.2rem;
        justify-content: space-between;
        padding: 1.12rem 0;
    }

    .storeList {
   /*     flex: 1;
        overflow: auto;*/
    }

    .storesTitle {
        padding-left: 0.454rem;
        border-left: 0.267rem solid rgba(251, 109, 55, 1);
        border-radius: 5px;
        width: 5.094rem;
        height: 1.227rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.28rem;
    }

    .list_1 {
        height: 23rem;
        overflow: auto;
    }

    .group_1 {
        justify-content: space-around;
    }

    .navItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .navItem img {
        width: 3.467rem;
        height: 3.467rem;
        margin-bottom: 0.26rem;
    }

    .navItem .span {
        color: #666;
    }

    /* 底部导航 */
    .navFooter {
        display: flex;
        height: 100%;
    }

    .navFooter li {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .navFooter li.active span {
        color: #fb6d36;
    }

    .navFooter li img {
        width: 1.86rem;
        height: 1.86rem;
    }

    .navFooter li span {
        display: inline-block;
        padding: 0.26rem 0 0 0;
        line-height: 1.28rem;
        font-size: 0.75rem;
        color: #666;
    }

    .box_6 {
        width: 30.454rem;
      /*  height: 1.494rem;*/
        margin: 0.5rem 0 1rem 1.36rem;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .label_7 {
        width: 0.907rem;
        height: 1.067rem;
        margin-top: 0.214rem;
    }

    .text_9 {
        width: 6.24rem;
        height: 1.014rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.066rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.067rem;
        margin: 0.214rem 0 0 0.507rem;
    }

    .image_9 {
        width: 1.494rem;
        height: 1.494rem;
        margin-left: 21.307rem;
    }

    .text_2 {
        width: 2.587rem;
        height: 0.907rem;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 0.96rem;
        font-family: SourceHanSansCN-Regular;
        font-weight: NaN;
        text-align: left;
        white-space: nowrap;
        line-height: 0.96rem;
    }

    .box_3 {
        background-color: rgba(251, 109, 55, 1);
        border-radius: 5px;
        width: 0.267rem;
        height: 1.334rem;
    }

    .text_1 {
        width: 5.094rem;
        height: 1.227rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.28rem;
        margin: 0.08rem 0 0 0.454rem;
    }

    /* stores copy */
    /* copy store */
    .storeJieshao {
        display: flex;
    }

    .storeJieshao > img {
        width: 8rem;
        height: 5.6rem;
        margin-right: 0.8rem;
    }

    .detail {
        display: flex;
        flex-direction: column;
    }

    .detail h3 {
        height: 1.38rem;
        line-height: 1.38rem;
        font-size: 1.06rem;
        color: #333;
    }

    .detail .rate {
        margin-top: 0.64rem;
    }

    .detail .rate img {
        width: 1.12rem;
        height: 1.12rem;
    }

    .businessHours {
        margin-top: 0.3rem;
    }

    .businessHours span:nth-child(1) {
        display: inline-block;
        padding: 0.13rem 0.32rem;
        font-size: 0.69rem;
        color: #fff;
        background-color: #fc7a3d;
        border-radius: 3px;
    }

    .businessHours span:nth-child(2) {
        font-size: 0.69rem;
        color: #999;
        margin-left: 0.53rem;
    }

    .storeAddress {
        margin-top: 0.8rem;
        font-size: 0.8rem;
        color: #999;
    }
</style>