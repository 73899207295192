import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'
import model from '../views/model/index.vue'
import login from '../views/register/login.vue'
import register from '../views/register/index.vue'
import invitationcode from '../views/register/invitationcode.vue'
import nearbyStoresList from '../views/nearbyStoresList/index.vue'
import wode from '../views/wode/index.vue'
// import nearbyStores from '../views/nearbyStores/index.vue'
// import addNewAddress from '../views/addNewAddress/index.vue'
// import storesInfo from '../views/storesInfo/index.vue'
// import goldMember from '../views/goldMember/index.vue'
// import enterMerchants from '../views/enterMerchants/index.vue'
// import userOrder from '../views/userOrder/index.vue'
// import userAddress from '../views/userAddress/index.vue'
// import numberOfDiners from '../views/numberOfDiners/index.vue'
// import inviteCode from '../views/inviteCode/index.vue'
// import stores from '../views/stores/index.vue'
// import mallInfo from '../views/mallInfo/index.vue'
// import mallConfirmOrder from '../views/mallConfirmOrder/index.vue'
// import userCoupon from '../views/userCoupon/index.vue'
// import scanCodeWithdrawal from '../views/scanCodeWithdrawal/index.vue'
// import userOrderInfo from '../views/userOrderInfo/index.vue'
// import withdrawal from '../views/withdrawal/index.vue'
// import wode from '../views/wode/index.vue'
// import enterMall from '../views/enterMall/index.vue'
// import membersIntroduction from '../views/membersIntroduction/index.vue'
// import payResults from '../views/payResults/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/model',
    name: 'model',
    component: model
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/invitationcode',
    name: 'invitationcode',
    component: invitationcode
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      footBarShow: true,
    },
    component: home
  },
  {
    path: '/wode',
    name: 'wode',
    meta: {
      footBarShow: true,
    },
    component: wode
  },
  {
    path: '/nearbyStoresList',
    name: 'nearbyStoresList',
    component: nearbyStoresList
  },
  {
    path: '/enterMall',
    name: 'enterMall',
    component: ()=>import('../views/enterMall/index.vue'),
    children:[
      {
        path: 'dishes',
        name: 'dishes',
        component: ()=> import('../views/enterMall/dishes.vue'),
      },
      {
        path: 'shangjia',
        name: 'shangjia',
        component: ()=> import('../views/enterMall/storeInfoItem.vue'),
      },
    ]
  },
  {
    path: '/stores',
    name: 'stores',
    meta: {
      footBarShow: true,
    },
    component: ()=>import('../views/stores/index.vue')
  },

  {
    path: '/orderInfo',
    name: 'orderInfo',
    component: () => import('../views/orderInfo/index.vue'),
  },
  {
    path: '/userOrder',
    name: 'userOrder',
    component: ()=>import('../views/userOrder/index.vue')
  },
  {
    path: '/userOrderInfo',
    name: 'userOrderInfo',
    component: ()=>import('../views/userOrderInfo/index.vue')
  },
  {
    path: '/membersIntroduction',
    name: 'membersIntroduction',
    component: ()=>import('../views/membersIntroduction/index.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    component: ()=>import('../views/activity/index.vue')
  },
  {
    path: '/inviteCode',
    name: 'inviteCode',
    component: ()=>import('../views/inviteCode/index.vue')
  },
  {
    path: '/userCoupon',
    name: 'userCoupon',
    component: ()=>import('../views/userCoupon/index.vue')
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: ()=>import('../views/addNewAddress/userinfo.vue')
  },
  {
    path: '/payResults',
    name: 'payResults',
    component: ()=>import('../views/payResults/index.vue')
  },
  {
    path: '/scanCodeWithdrawal',
    name: 'scanCodeWithdrawal',
    component: ()=>import('../views/scanCodeWithdrawal/index.vue')
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: ()=>import('../views/withdrawal/index.vue')
  },
  {
    path: '/withdrawaltxjl',
    name: 'withdrawaltxjl',
    component: ()=>import('../views/withdrawal/index_txjl.vue')
  },
  {
    path: '/withdrawaltg',
    name: 'withdrawaltg',
    component: ()=>import('../views/withdrawal/tgindex.vue')
  },
  {
    path: '/userAddress',
    name: 'userAddress',
    component: ()=>import('../views/userAddress/index.vue')
  },
  {
    path: '/addNewAddress',
    name: 'addNewAddress',
    component: ()=>import('../views/addNewAddress/index.vue')
  },

//以下未调试
/*  {
    path: '/nearbyStores',
    name: 'nearbyStores',
    component: ()=> import('../views/nearbyStores/index1.vue'),
    children:[
      {
        path: 'dishes',
        name: 'dishes',
        component: ()=> import('../views/nearbyStores/dishes.vue'),
      },
      {
        path: 'shangjia',
        name: 'shangjia',
        component: ()=> import('../views/nearbyStores/storeItem.vue'),
      },
    ]
  },*/


  {
    path: '/storesInfo',
    name: 'storesInfo',
    component: ()=>import('../views/storesInfo/index.vue'),
    children:[
      {
        path: 'caipins',
        name: 'caipins',
        component: ()=> import('../views/storesInfo/caipins.vue'),
      },
      {
        path: 'storeInfoItem',
        name: 'storeInfoItem',
        component: ()=> import('../views/storesInfo/storeInfoItem.vue'),
      },
    ],
  },
  {
    path: '/goldMember',
    name: 'goldMember',
    component: ()=>import('../views/goldMember/index.vue')
  },
  {
    path: '/enterMerchants',
    name: 'enterMerchants',
    component: ()=>import('../views/enterMerchants/index.vue')
  },
  {
    path: '/numberOfDiners',
    name: 'numberOfDiners',
    component: ()=>import('../views/numberOfDiners/index.vue')
  },
  {
    path: '/mallInfo',
    name: 'mallInfo',
    component: ()=>import('../views/mallInfo/index.vue')
  },
  {
    path: '/mallConfirmOrder',
    name: 'mallConfirmOrder',
    component: ()=>import('../views/mallConfirmOrder/index.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
