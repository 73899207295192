import request from '@/api/request'
// 会员注册
export function register(data) {
  return request({
    url: '/external/interface/register',
    method: 'post',
    data: data
  })
}

// 会员登录
export function login(data) {
  return request({
    url: '/external/interface/login',
    method: 'post',
    data: data
  })
}

// 会员查询
export function queryCode(data) {
  return request({
    url: '/external/interface/queryCode?code='+data,
    method: 'get'
  })
}

// 会员信息查询
export function queryUserByAccount(data) {
  return request({
    url: '/external/interface/queryUserByAccount?account='+data,
    method: 'get'
  })
}

// 会员信息查询
export function queryUserByMemberId(data) {
  return request({
    url: '/external/interface/queryUserByMemberId?memberId='+data,
    method: 'get'
  })
}

//保存上级邀请码
export function saveFatherCode(data) {
  return request({
    url: '/external/interface/saveFatherCode',
    method: 'post',
    data: data
  })
}

// 查询会员优惠券信息
export function queryUserVoucher(data) {
  return request({
    url: '/external/interface/queryUserVoucher',
    method: 'post',
    data: data
  })
}

//保存个人信息
export function saveMemberInfo(data) {
  return request({
    url: '/external/interface/saveMemberInfo',
    method: 'post',
    data: data
  })
}

//活动查询
export function queryActivity() {
  return request({
    url: '/external/interface/queryActivity',
    method: 'get'
  })
}

// 收益查询
export function queryMemberSy(data) {
  return request({
    url: '/external/interface/queryMemberSy?account='+data,
    method: 'get'
  })
}

//提现查询
export function queryMemberTx(data) {
  return request({
    url: '/external/interface/queryMemberTx?account='+data,
    method: 'get'
  })
}

//扫码提现
export function scanCodeTx(data) {
  return request({
    url: '/external/interface/scanCodeTx',
    method: 'post',
    data: data
  })
}

// 推广查询
export function queryMemberTg(data) {
  return request({
    url: '/external/interface/queryMemberTg?account='+data,
    method: 'get'
  })
}

export function rallbackScheduleConfig(data) {
  return request({
    url: '/external/interface/rallbackScheduleConfig?account='+data,
    method: 'get'
  })
}

// 地址查询
export function queryOrderAddressByMemberId(data) {
  return request({
    url: '/external/interface/queryOrderAddressByMemberId?memberId='+data,
    method: 'get'
  })
}

// 地址详情
export function updateAddress(data) {
  return request({
    url: '/external/interface/queryOrderAddressById?addressId='+data,
    method: 'get'
  })
}

// 地址保存
export function saveAddress(data) {
  return request({
    url: '/external/interface/saveOrderAddressByMemberId',
    method: 'post',
    data: data
  })
}

// 地址删除
export function delAddress(data) {
  return request({
    url: '/external/interface/delOrderAddressByMemberId?addressId='+data,
    method: 'get'
  })
}

// 地址添加
export function addAddress(data) {
  return request({
    url: '/external/interface/addOrderAddressByMemberId',
    method: 'post',
    data: data
  })
}

export function queryShopAreaList(data) {
  return request({
    url: '/external/interface/queryShopAreaList?shopArea='+data,
    method: 'get'
  })
}